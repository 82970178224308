import NavigationTypes from "../Enums/NavigationTypes";
import UserRoles from "../Enums/UserRoles";
import INavigation from "../Intefaces/INavigation";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import StoreIcon from '@mui/icons-material/Store';
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import TimesOneMobiledataIcon from '@mui/icons-material/TimesOneMobiledata';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import HandymanIcon from '@mui/icons-material/Handyman';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PeopleIcon from '@mui/icons-material/People';
import FeedIcon from '@mui/icons-material/Feed';
import ChatIcon from '@mui/icons-material/Chat';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import BadgeIcon from '@mui/icons-material/Badge';
import AddCardIcon from '@mui/icons-material/AddCard';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const SideBarNavigation: INavigation[] = [
    {
        Name: "Dashboard",
        Icon: DashboardIcon,
        roles: [UserRoles.ADMIN, UserRoles.PERSONAL, UserRoles.COMPANY, UserRoles.BANK],
        type: NavigationTypes.LINK,
        active: true,
        link: "/",
        validator: async (user: any): Promise<boolean> => {
            return true;
        },
        action: async (user: any): Promise<void> => {

        }
    },
    {
        Name: "Request Loan",
        Icon: AddCardIcon,
        roles: [UserRoles.PERSONAL, UserRoles.COMPANY],
        type: NavigationTypes.LINK,
        active: true,
        link: "/loanrequest",
        validator: async (user: any): Promise<boolean> => {
            return true;
        },
        action: async (user: any): Promise<void> => {

        }
    },
    {
        Name: "Banks",
        Icon: AssuredWorkloadIcon,
        roles: [UserRoles.ADMIN, UserRoles.BANK],
        type: NavigationTypes.LINK,
        active: true,
        link: "/list/tbl_bank",
        validator: async (user: any): Promise<boolean> => {
            return true;
        },
        action: async (user: any): Promise<void> => {

        }
    },
    {
        Name: "Business Plans",
        Icon: BusinessCenterIcon,
        roles: [UserRoles.ADMIN,UserRoles.PERSONAL, UserRoles.COMPANY],
        type: NavigationTypes.LINK,
        active: true,
        link: "/list/tbl_businessplan",
        validator: async (user: any): Promise<boolean> => {
            return true;
        },
        action: async (user: any): Promise<void> => {

        }
    },
    {
        Name: "Companies",
        Icon: ApartmentIcon,
        roles: [UserRoles.ADMIN, UserRoles.COMPANY],
        type: NavigationTypes.LINK,
        active: true,
        link: "/list/tbl_company",
        validator: async (user: any): Promise<boolean> => {
            return true;
        },
        action: async (user: any): Promise<void> => {

        }
    },
    {
        Name: "Company Economic",
        Icon: HomeRepairServiceIcon,
        roles: [UserRoles.ADMIN, UserRoles.COMPANY],
        type: NavigationTypes.LINK,
        active: true,
        link: "/list/tbl_companyeconomic",
        validator: async (user: any): Promise<boolean> => {
            return true;
        },
        action: async (user: any): Promise<void> => {

        }
    },
    {
        Name: "Personal Economic",
        Icon: AccountBalanceWalletIcon,
        roles: [UserRoles.ADMIN, UserRoles.PERSONAL],
        type: NavigationTypes.LINK,
        active: true,
        link: "/list/tbl_economic",
        validator: async (user: any): Promise<boolean> => {
            return true;
        },
        action: async (user: any): Promise<void> => {

        }
    },
    {
        Name: "Loans",
        Icon: CreditScoreIcon,
        roles: [UserRoles.ADMIN, UserRoles.PERSONAL, UserRoles.BANK, UserRoles.COMPANY],
        type: NavigationTypes.LINK,
        active: true,
        link: "/list/tbl_loan",
        validator: async (user: any): Promise<boolean> => {
            return true;
        },
        action: async (user: any): Promise<void> => {

        }
    },
    {
        Name: "Personal User",
        Icon: BadgeIcon,
        roles: [UserRoles.ADMIN, UserRoles.PERSONAL],
        type: NavigationTypes.LINK,
        active: true,
        link: "/list/tbl_personal",
        validator: async (user: any): Promise<boolean> => {
            return true;
        },
        action: async (user: any): Promise<void> => {

        }
    },
    {
        Name: "Users",
        Icon: PeopleIcon,
        roles: [UserRoles.ADMIN],
        type: NavigationTypes.LINK,
        active: true,
        link: "/list/tbl_user",
        validator: async (user: any): Promise<boolean> => {
            return true;
        },
        action: async (user: any): Promise<void> => {

        }
    },
    {
        Name: "Account",
        Icon: AccountBoxIcon,
        roles: [ UserRoles.ADMIN, UserRoles.PERSONAL, UserRoles.COMPANY, UserRoles.BANK],
        type: NavigationTypes.LINK,
        active: true,
        link: "/profile",
        validator: async (user: any): Promise<boolean> => {
            return true;
        },
        action: async (user: any): Promise<void> => {

        }
    },
    // {
    //     Name: "Sign Out",
    //     Icon: LogoutIcon,
    //     roles: [UserRoles.COMPANY, UserRoles.CASHIER, UserRoles.ADMIN],
    //     type: NavigationTypes.ACTION,
    //     active: true,
    //     validator: async (user: any): Promise<boolean> => {
    //         return true;
    //     },
    //     action: async (user: any): Promise<void> => {

    //     }
    // },

];

export default SideBarNavigation;