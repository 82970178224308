import FieldTypes from "../Enums/FiedTypes";
import UserRoles from "../Enums/UserRoles";
import UserState from "../Enums/UserState";
import IField from "../Intefaces/IField";
import IForm from "../Intefaces/IForm";
import IPagination from "../Intefaces/IPagination";
import Utils from "../Models/Utils";
import LocalData from "../Intefaces/LocalData";
import AuthResult from "../Intefaces/AuthResult";
import Ticket from "../Models/Ticket";
import MainAPI from "../APIs/MainAPI";
import EducationStatus from "../Enums/EducationStatus";
import MaritalStatus from "../Enums/MaritalStatus";
import CriminalRecord from "../Enums/CriminalRecord";
import CollateralType from "../Enums/CollateralType";
import JobStatus from "../Enums/JobStatus";
import LegalStatus from "../Enums/LefalStatus";
import Sectors from "../Enums/Sectors";
import PlanStage from "../Enums/PlanStage";
import LoanStatus from "../Enums/LoanStatus";
import { Visibility } from "@mui/icons-material";
import Operators from "../Enums/Operators";

const localRoles = [
    { value: UserRoles.ADMIN, label: "Admin" },
    { value: UserRoles.BANK, label: "Bank" },
    { value: UserRoles.PERSONAL, label: "Personal User" },
    { value: UserRoles.COMPANY, label: "Company User" }
];

const localUserState = [
    { value: UserState.ACTIVE, label: "Active" },
    { value: UserState.INACTIVE, label: "Inactive" }
];
const localLegalStatus = [
    { value: LegalStatus.PLC, label: "Public/Private Limited" },
    { value: LegalStatus.ProprietorShip, label: "Proprietorship" },
    { value: LegalStatus.PartnerShip, label: "Prtnership" }
];

const localSectors = [
    { value: Sectors.Agricalture, label: "Agricalture" },
    { value: Sectors.Service, label: "Service" },
    { value: Sectors.Tech, label: "Technology" },
    { value: Sectors.Manufacture, label: "Manufacturing" },
    { value: Sectors.Construction, label: "Construction" }
];
const localPlanStage = [
    { value: PlanStage.Ideation, label: "Ideation Stage" },
    { value: PlanStage.Revenue, label: "Revenue MVP" },
    { value: PlanStage.PostRevenue, label: "Post Revenue" }
];

const localLoanStatus = [
    { value: LoanStatus.Requested, label: "Requested" },
    { value: LoanStatus.Approved, label: "Approved" },
    { value: LoanStatus.Rejected, label: "Rejected" },
    { value: LoanStatus.Received, label: "Received" },
    { value: LoanStatus.Repaid, label: "Repaid" },
];

const localJobStatus = [
    { value: JobStatus.Employed, label: "Employed" },
    { value: JobStatus.SelfEmployed, label: "Self Employed" },
    { value: JobStatus.Unemployed, label: "Unemployed" }
];

const localCollateralTypes = [
    { value: CollateralType.Building, label: "Building" },
    { value: CollateralType.Vehicle, label: "Vehichle" },
    { value: CollateralType.Both, label: "Building and Vehichle" }
];

const localEducationLevel = [
    { value: EducationStatus.BelowHighSchool, label: "Below High School" },
    { value: EducationStatus.HighSchool, label: "High School" },
    { value: EducationStatus.Diploma, label: "Deploma" },
    { value: EducationStatus.Bachelors, label: "Bachelors Degree" },
    { value: EducationStatus.Masters, label: "Masters Degree" },
    { value: EducationStatus.PHD, label: "PHD" }
];

const localCriminalRecord = [
    { value: CriminalRecord.No, label: "No" },
    { value: CriminalRecord.PastFive, label: "Past/Five Years" },
    { value: CriminalRecord.MoreThanFive, label: "More Than Five Years" },
];

const mapMultiple = (fields: any, mappings: any[], data: any[]) => {

}

const mapSingle = (fields: IField[], mappings: any, data: any) => {
    return fields.map(fld => {

        let mpfunc = mappings[fld.id];
        if(mpfunc) {
            return {
                ...fld,
                ...mpfunc(data)
            };
        }else {return fld;}

    });
}

const uploadImage = async (token: string, table: string, attachment: {file: any, name: string}): Promise<number> => {
	try {
		let response = await MainAPI.addAttachment(token, table, 0, attachment);
		return parseInt(response.id);
	}catch(error){
		return 0;
	}
}

const mapValue = async (fields: IField[], token?: string, table?: string) => {

	let new_instance: any = {};

	// fields.forEach(async (single_field) => {
	let single_field;

	for(let i = 0; i < fields.length; i++) {
		single_field = fields[i];
        // console.log(`single field ${single_field.id} value `, single_field.value, `${single_field.value}` == "");
		if((`${single_field.value}` == "") || single_field.value == null || ((single_field.type == FieldTypes.NUMBER || single_field.type == FieldTypes.REFERENCE) && (single_field.value != 0 && Number.isNaN(single_field.value)))) {
			if(single_field.required) {
				throw new Error(`The field ${single_field.label} is required!`);
			}
		} else {
			// if(single_field.type == FieldTypes.NUMBER || single_field.type == FieldTypes.REFERENCE) {
			// 	new_instance[single_field.id] = Number.isInteger(single_field.value) ? parseInt(single_field.value) : parseFloat(single_field.value);
			// } else 
            if((single_field.type == FieldTypes.DATE || single_field.type == FieldTypes.DATETIME)) {
				new_instance[single_field.id] = new Date(single_field.value).toISOString();
			} else if(single_field.type == FieldTypes.IMAGE) {

                if(!Number.isInteger(single_field.value)) {
                    new_instance[single_field.id] = await uploadImage(token ?? "user", table ?? "user", {
                        file: single_field.value.files[0],
                        name: "image from input"
                    });
                }else {
                    new_instance[single_field.id] = single_field.value;
                }

			} else {
				new_instance[single_field.id] = single_field.value;
			}
		}
	};

	return new_instance;
}

const tables: IForm[] = [
    {
        title: "Personal User",
        id: "tbl_personal",
        roles: [UserRoles.ADMIN, UserRoles.PERSONAL, UserRoles.BANK],
        actions: [
            {
                roles: [UserRoles.ADMIN, UserRoles.PERSONAL],
                lable: "Update",
                class: "btn-light bg-white shadow-sm",
                action: async (token: string, fields: IField[]) => {
                    // let new_odd: any = {};
                    // fields.forEach(fld => {new_odd[fld.id] = fld.value;});
                    let new_instance = await mapValue(fields, token, "personal");
                    new_instance.tin = new_instance.tin.toString();
                    await MainAPI.update(token, "personal",  new_instance);
                }
            }

        ],
        relatedList: [],
        fields: [
            {
                id: "id",
                label: "Identifier",
                type: FieldTypes.NUMBER,
                description: "",
                value: "",
                order: 1,
                required: false,
                visible: true,
                readonly: true,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "user_id",
                label: "Account",
                type: FieldTypes.REFERENCE,
                description: "Personal Related User Account",
                value: "",
                references: "tbl_user",
                order: 10,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "tin",
                label: "TIN Number",
                type: FieldTypes.TEXT,
                description: "Tax Payer TIN Number",
                value: "",
                order: 20,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "age",
                label: "Age",
                type: FieldTypes.NUMBER,
                description: "User Age.",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "dependants",
                label: "Dependants Number",
                type: FieldTypes.NUMBER,
                description: "Number of people depended on the user",
                value: "",
                order: 50,
                required: true,
                visible: true,
                readonly: false,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "education",
                label: "Education Level",
                type: FieldTypes.SELECT,
                description: "User Level Of Education.",
                value: "",
                order: 40,
                options: localEducationLevel,
                required: true,
                visible: true,
                readonly: false,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "marital_status",
                label: "Marital Status",
                type: FieldTypes.SELECT,
                description: "Personal Marital Status",
                value: "",
                order: 60,
                required: true,
                visible: true,
                readonly: false,
                options: [
                    { value: MaritalStatus.Married, label: "Merried" },
                    { value: MaritalStatus.Unmerried, label: "Not Merried" }
                ],
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "criminal",
                label: "Criminal Record",
                type: FieldTypes.SELECT,
                description: "If there is previous criminal record",
                value: "",
                order: 70,
                required: true,
                visible: true,
                readonly: false,
                options: localCriminalRecord,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "score",
                label: "Score",
                type: FieldTypes.NUMBER,
                description: "System Calculated Score",
                value: "",
                order: 70,
                required: false,
                visible: true,
                readonly: true,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            }
        ],

        onsubmit: async (token: string, fields: IField[]): Promise<void> => {

            return await MainAPI.createNew(token, "personal", await mapValue(fields, token, "personal"));

        },

        onload: async (token: string, all_fields: IField[], localData: (LocalData | any), loggedUser: (AuthResult | any), id?: any): Promise<IField[]> => {

            let is_admin = loggedUser.Roles.includes(UserRoles.ADMIN);
            let is_personal = loggedUser.Roles.includes(UserRoles.PERSONAL);
            let is_company = loggedUser.Roles.includes(UserRoles.COMPANY);
            let is_bank = loggedUser.Roles.includes(UserRoles.BANK);

            let data = {};
            if((parseInt(id) > 0)){
                data = await MainAPI.getSingle(token, "personal", id);
            }

            return mapSingle(all_fields, {
                "id": (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.id : "",
                }),
                "user_id": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.user_id : "",
                    options: localData.PersonalUsers,
                    readonly: (!is_personal && !is_admin)
                }),
                "tin": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.tin : "",
                    readonly: (!is_personal && !is_admin)
                }),
                "age": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.age : "",
                    readonly: (!is_personal && !is_admin)
                }),
                "education": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.education : "",
                    readonly: (!is_personal && !is_admin)
                }),
                "marital_status": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.marital_status : "",
                    readonly: (!is_personal && !is_admin)
                }),
                "dependants": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.dependants : "",
                    readonly: (!is_personal && !is_admin)
                }),
                "criminal": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.criminal : "",
                    readonly: (!is_personal && !is_admin)
                }),
                "score": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.score : "",
                    visible: !is_personal && !is_company
                })

            }, data);

        },

        listLoader: async (token: string, pageNumber: number, pageSize: number, localData: (LocalData | any), condition?: any): Promise<IPagination<any>> => {

            let data = await MainAPI.getAll(token, "personal", pageNumber, pageSize, condition);
            let records = data.Items.map((rec) => ({
                ...rec,
                education: Utils.getFromArray("value", rec.education, "label", localEducationLevel),
                marital_status: Utils.getFromArray("value", rec.marital_status, "label", [
                    { value: MaritalStatus.Married, label: "Merried" },
                    { value: MaritalStatus.Unmerried, label: "Not Merried" }
                ]),
                criminal: Utils.getFromArray("value", rec.criminal, "label", localCriminalRecord)
            }));

            data.Items = records;
            return data;

        }
    },
    {
        title: "Users",
        id: "tbl_user",
        roles: [UserRoles.ADMIN],
        actions: [
            {
                roles: [UserRoles.ADMIN],
                lable: "Update",
                class: "btn-light bg-white shadow-sm",
                action: async (token: string, fields: IField[]) => {
                    let new_user: any = {};

                    fields.forEach(fld => {
                        if(fld.id != "password") {
                            new_user[fld.id] = fld.value;
                        }
                        // if ("NextPaymentDate" == fld.id) {
                        //     new_company.NextPaymentDate = new Date(fld.value).getTime() / 1000;
                        // } else {
                        //     new_company[fld.id] = fld.value;
                        // }
                    });

                    await MainAPI.update(token, "user", new_user);
                }
            }
        ],
        relatedList: [
            {
                id: "rl_loan",
                form: "tbl_loan",
                lable: "User Loans",
                relatingColumn: "user_id",
                loader: (fields: IField[]): any => {

                    let field = fields.find(fld => (fld.id == "id"));
                    return {
                        user_id: {
                            value: field ? field.value : "",
                            operator: Operators.IS,
                            type: field ? field.type : FieldTypes.NUMBER
                        }
                    };

                }
            },
            {
                id: "rl_personal",
                form: "tbl_personal",
                lable: "Personal Info",
                relatingColumn: "user_id",
                loader: (fields: IField[]): any => {

                    let field = fields.find(fld => (fld.id == "id"));
                    return {
                        user_id: {
                            value: field ? field.value : "",
                            operator: Operators.IS,
                            type: field ? field.type : FieldTypes.NUMBER
                        }
                    };

                }
            },
            {
                id: "rl_company",
                form: "tbl_company",
                lable: "Company Info",
                relatingColumn: "user_id",
                loader: (fields: IField[]): any => {

                    let field = fields.find(fld => (fld.id == "id"));
                    return {
                        user_id: {
                            value: field ? field.value : "",
                            operator: Operators.IS,
                            type: field ? field.type : FieldTypes.NUMBER
                        }
                    };

                }
            },
            {
                id: "rl_economic",
                form: "tbl_economic",
                lable: "Economic Info",
                relatingColumn: "user_id",
                loader: (fields: IField[]): any => {

                    let field = fields.find(fld => (fld.id == "id"));
                    return {
                        user_id: {
                            value: field ? field.value : "",
                            operator: Operators.IS,
                            type: field ? field.type : FieldTypes.NUMBER
                        }
                    };

                }
            },
        ],
        fields: [
            {
                id: "id",
                label: "Identifier",
                type: FieldTypes.NUMBER,
                description: "User Id",
                value: "",
                order: 1,
                required: false,
                visible: true,
                readonly: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "FullName",
                label: "Full Name",
                type: FieldTypes.TEXT,
                description: "User Full Name",
                value: "",
                order: 10,
                required: true,
                visible: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "Phone",
                label: "Phone Number",
                type: FieldTypes.TEXT,
                description: "Users Phone Number",
                value: "",
                order: 10,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "Email",
                label: "Email Address",
                type: FieldTypes.EMAIL,
                description: "User Email Address",
                value: "",
                order: 20,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "Status",
                label: "Account State",
                type: FieldTypes.SELECT,
                description: "Account State",
                value: "",
                order: 20,
                required: true,
                visible: true,
                readonly: false,
                options: [
                    {
                        value: UserState.ACTIVE,
                        label: "Active"
                    },
                    {
                        value: UserState.INACTIVE,
                        label: "InActive"
                    }
                ],
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "Role",
                label: "Role",
                type: FieldTypes.SELECT,
                description: "User Role",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: true,
                options: localRoles,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "Password",
                label: "Password",
                type: FieldTypes.PASSWORD,
                description: "User Password",
                value: "",
                order: 20,
                required: true,
                visible: false,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            }
        ],

        onsubmit: async (token: string, fields: IField[]): Promise<void> => {

            let new_company: any = {
                FullName: "",
                Username: "",
                Phone: "",
                Email: "",
                Role: "",
                Status: "",
                Password: "",
            };

            fields.forEach(fld => {
                if(new_company[fld.id] != undefined) {
                    new_company[fld.id] = fld.value;
                }
            });

            new_company.Username = new_company.Email;

            return await MainAPI.createNew(token, "user", new_company);

        },

        onload: async (token: string, all_fields: IField[], localData: (LocalData | any), loggedUser: (AuthResult | any), id?: any): Promise<IField[]> => {
            if (parseInt(id) <= 0) {
                let mappings = {
                    "id": (data: any) => ({
                        value: "",
                        // visible: false
                    }),
                    "FullName": (data: any) => ({
                        value: ""
                    }),
                    "Phone": (data: any) => ({
                        value: ""
                    }),
                    "Email": (data: any) => ({
                        value: ""
                    }),
                    "Role": (data: any) => ({
                        value: "",
                        readonly: false
                    }),
                    "Status": (data: any) => ({
                        value: "",
                        readonly: false
                    }),
                    "Password": (data: any) => ({
                        value: ""
                    }),
                };

                return mapSingle(all_fields, mappings, {});
                // return all_fields.map(fld => {fld.value = ""; return fld;});
            }

            let data = await MainAPI.getSingle(token, "user", id);
            if (data) {
                let mappings = {
                    "id": (data: any) => ({
                        value: data.id
                    }),
                    "FullName": (data: any) => ({
                        value: data.FullName
                    }),
                    "Phone": (data: any) => ({
                        value: data.Phone
                    }),
                    "Email": (data: any) => ({
                        value: data.Email
                    }),
                    "Role": (data: any) => ({
                        value: data.Role
                    }),
                    "Status": (data: any) => ({
                        value: data.Status
                    }),
                    "Password": (data: any) => ({
                        value: data.Password
                    }),
                };

                return mapSingle(all_fields, mappings, data);
            }

            return all_fields;

        },

        listLoader: async (token: string, pageNumber: number, pageSize: number, localData: (LocalData | any), condition?: any): Promise<IPagination<any>> => {

            let data = await MainAPI.getAll(token, "user", pageNumber, pageSize, condition);

            let records = data.Items.map((rec) => ({
                ...rec,
                Role: Utils.getFromArray("value", rec.Role, "label", localRoles),
                Status: Utils.getFromArray("value", rec.Status, "label", [
                    { value: UserState.ACTIVE, label: "Active" },
                    { value: UserState.INACTIVE, label: "InActive"}
                ])
            }));

            data.Items = records;
            return data;

        }
    },
    {
        title: "Bank",
        id: "tbl_bank",
        roles: [UserRoles.ADMIN, UserRoles.BANK],
        actions: [
            {
                roles: [UserRoles.ADMIN, UserRoles.BANK],
                lable: "Update",
                class: "btn-light bg-white shadow-sm",
                action: async (token: string, fields: IField[]) => {
                    let new_instance = await mapValue(fields, token, "bank");

                    await MainAPI.update(token, "bank", new_instance);
                }
            }
        ],
        relatedList: [
            {
                id: "rl_loan",
                form: "tbl_loan",
                lable: "Loans",
                relatingColumn: "bank_id",
                loader: (fields: IField[]): any => {

                    let field = fields.find(fld => (fld.id == "id"));
                    return {
                        bank: {
                            value: field ? field.value : "",
                            operator: Operators.IS,
                            type: field ? field.type : FieldTypes.NUMBER
                        }
                    };

                }
            }
        ],
        fields: [
            {
                id: "id",
                label: "Identifier",
                type: FieldTypes.NUMBER,
                description: "Blog Id",
                value: "",
                order: 1,
                required: false,
                visible: true,
                readonly: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "name",
                label: "Bank Name",
                type: FieldTypes.TEXT,
                description: "Offical Bank Name",
                value: "",
                order: 1,
                required: true,
                visible: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "user_id",
                label: "Account",
                type: FieldTypes.REFERENCE,
                description: "Related Ebidir Account",
                references: "tbl_user",
                value: "",
                order: 1,
                required: true,
                visible: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "logoColor",
                label: "Logo Color",
                type: FieldTypes.COLOR,
                description: "Offical Bank Accent Color",
                value: "",
                order: 1,
                required: true,
                visible: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    console.log("color value ", value);
                    return value;
                }
            },
            {
                id: "image",
                label: "Logo",
                type: FieldTypes.IMAGE,
                description: "Bank Logo",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            }

        ],

        onsubmit: async (token: string, fields: IField[]): Promise<void> => {

            let new_instance = await mapValue(fields, token, "bank");
            return await MainAPI.createNew(token, "bank", new_instance);

        },

        onload: async (token: string, all_fields: IField[], localData: (LocalData | any), loggedUser: (AuthResult | any), id?: any): Promise<IField[]> => {

            let is_admin = loggedUser.Roles.includes(UserRoles.ADMIN);
            let is_personal = loggedUser.Roles.includes(UserRoles.PERSONAL);
            let is_company = loggedUser.Roles.includes(UserRoles.COMPANY);
            let is_bank = loggedUser.Roles.includes(UserRoles.BANK);

            let data = {};
            if((parseInt(id) > 0)){
                data = await MainAPI.getSingle(token, "bank", id);
            }

            return mapSingle(all_fields, {
                id: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.id : "",
                }),
                "name": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.name : "",
                    readonly: (!is_bank && !is_admin)
                }),
                "user_id": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.user_id : "",
                    options: localData.BankUsers,
                    readonly: (!is_bank && !is_admin)
                }),
                "image": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.image : "",
                    readonly: (!is_bank && !is_admin)
                }),
                "logoColor": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.logoColor : "",
                })
            }, data);

        },

        listLoader: async (token: string, pageNumber: number, pageSize: number, localData: (LocalData | any), condition?: any): Promise<IPagination<any>> => {

            let data = await MainAPI.getAll(token, "bank", pageNumber, pageSize, condition);
            return data;

        }
    },
    {
        title: "Company",
        id: "tbl_company",
        roles: [UserRoles.ADMIN, UserRoles.BANK, UserRoles.COMPANY],
        actions: [
            {
                roles: [UserRoles.ADMIN, UserRoles.COMPANY],
                lable: "Update",
                class: "btn-light bg-white shadow-sm",
                action: async (token: string, fields: IField[]) => {
                    let new_odd: any = await mapValue(fields, token, "company");
                    await MainAPI.update(token, "company", new_odd);
                }
            }
        ],
        relatedList: [
            {
                id: "rl_loan",
                form: "tbl_loan",
                lable: "Company Loans",
                relatingColumn: "company_id",
                loader: (fields: IField[]): any => {

                    let field = fields.find(fld => (fld.id == "id"));
                    return {
                        company_id: {
                            value: field ? field.value : "",
                            operator: Operators.IS,
                            type: field ? field.type : FieldTypes.NUMBER
                        }
                    };

                }
            },
            {
                id: "rl_economic",
                form: "tbl_companyeconomic",
                lable: "Economic Info",
                relatingColumn: "user_id",
                loader: (fields: IField[]): any => {

                    let field = fields.find(fld => (fld.id == "id"));
                    return {
                        user_id: {
                            value: field ? field.value : "",
                            operator: Operators.IS,
                            type: field ? field.type : FieldTypes.NUMBER
                        }
                    };

                }
            }
        ],
        fields: [
            {
                id: "id",
                label: "Identifier",
                type: FieldTypes.NUMBER,
                description: "Company Id",
                value: "",
                order: 1,
                required: false,
                visible: true,
                readonly: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "user_id",
                label: "Account",
                type: FieldTypes.REFERENCE,
                description: "Related Company Account",
                value: "",
                references: "tbl_user",
                order: 10,
                required: true,
                visible: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "name",
                label: "Name",
                type: FieldTypes.TEXT,
                description: "Company or Organization Name",
                value: "",
                order: 20,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "tin",
                label: "TIN Number",
                type: FieldTypes.TEXT,
                description: "Tax Payer TIN Number",
                value: "",
                order: 20,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "legal_status",
                label: "Legal Status",
                type: FieldTypes.SELECT,
                description: "Legal Status of the Company",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                options: localLegalStatus,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "sector",
                label: "Sector",
                type: FieldTypes.SELECT,
                description: "Organization Work Sector",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                options: localSectors,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "score",
                label: "Score",
                type: FieldTypes.NUMBER,
                description: "Calculated Score",
                value: "",
                order: 30,
                required: false,
                visible: false,
                readonly: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            }

        ],

        onsubmit: async (token: string, fields: IField[]): Promise<any> => {

            let new_company = await mapValue(fields, token, "company");
            return await MainAPI.createNew(token, "company", new_company);

        },

        onload: async (token: string, all_fields: IField[], localData: (LocalData | any), loggedUser: (AuthResult | any), id?: any): Promise<IField[]> => {
            
            
            let is_admin = loggedUser.Roles.includes(UserRoles.ADMIN);
            let is_personal = loggedUser.Roles.includes(UserRoles.PERSONAL);
            let is_company = loggedUser.Roles.includes(UserRoles.COMPANY);
            let is_bank = loggedUser.Roles.includes(UserRoles.BANK);

            let data = {};
            if((parseInt(id) > 0)){
                data = await MainAPI.getSingle(token, "company", id);
            }

            return mapSingle(all_fields, {
                id: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.id : "",
                }),
                "user_id": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.user_id : "",
                    options: localData.CompanyUsers,
                    readonly: (!is_company && !is_admin)
                }),
                "name": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.name : "",
                    readonly: (!is_company && !is_admin)
                }),
                "tin": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.tin : "",
                    options: localData.CompanyUsers,
                    readonly: (!is_company && !is_admin)
                }),
                "legal_status": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.legal_status : "",
                    readonly: (!is_company && !is_admin)
                }),
                "sector": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.sector : "",
                    readonly: (!is_company && !is_admin)
                }),
                "score": (data: any) => ({
                    value: (parseInt(id) > 0) ? data.score : "",
                })
            }, data);

        },

        listLoader: async (token: string, pageNumber: number, pageSize: number, localData: (LocalData | any), condition?: any): Promise<IPagination<any>> => {

            let data = await MainAPI.getAll(token, "company", pageNumber, pageSize, condition);
            // let temp_items = [];
            // let temp_items = data.Items.map(dt => ({...dt, date: Utils.convertISOToDate(dt.date)}));
            // data.Items = temp_items;
            return data;

        }
    },
    {
        title: "Business Plan",
        id: "tbl_businessplan",
        roles: [UserRoles.ADMIN, UserRoles.BANK, UserRoles.PERSONAL, UserRoles.COMPANY],
        actions: [
            {
                roles: [UserRoles.ADMIN, UserRoles.PERSONAL, UserRoles.COMPANY],
                lable: "Update",
                class: "btn-light bg-white shadow-sm",
                condition: (token: string, fields: IField[], loggedUser: AuthResult): boolean => {

                    let user_field = fields.find(fld => fld.id == "user_id");
					let company_field = fields.find(fld => fld.id == "company_id");

                    if(loggedUser.Roles.includes(UserRoles.COMPANY)) {
                        return (company_field != undefined && company_field != null && loggedUser.companyId == company_field.value);
                    }

					return (user_field != undefined && user_field != null && loggedUser.Id == user_field.value);

				},
                action: async (token: string, fields: IField[]) => {

                    let new_plan = await mapValue(fields, token, "businessplan");
                    new_plan.total_income = 1;
                    await MainAPI.update(token, "businessplan", new_plan);

                }
            }
        ],
        relatedList: [
            {
                id: "rl_loan",
                form: "tbl_loan",
                lable: "Loans",
                relatingColumn: "business_plan_id",
                loader: (fields: IField[]): any => {

                    let field = fields.find(fld => (fld.id == "id"));
                    return {
                        business_plan_id: {
                            value: field ? field.value : "",
                            operator: Operators.IS,
                            type: field ? field.type : FieldTypes.NUMBER
                        }
                    };

                }
            }
        ],
        fields: [
            {
                id: "id",
                label: "Identifier",
                type: FieldTypes.NUMBER,
                description: "Business Plan Id",
                value: "",
                order: 1,
                required: false,
                visible: true,
                readonly: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "sector",
                label: "Sector",
                type: FieldTypes.SELECT,
                description: "Business Plan Sector",
                value: "",
                order: 10,
                required: true,
                visible: true,
                options: localSectors,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "sub_sector",
                label: "Subsector",
                type: FieldTypes.TEXT,
                description: "sub sector",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "user_id",
                label: "Account",
                type: FieldTypes.REFERENCE,
                description: "Related user account",
                value: "",
                references: "tbl_user",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "company_id",
                label: "Company",
                type: FieldTypes.REFERENCE,
                description: "Related company",
                value: "",
                references: "tbl_company",
                order: 30,
                required: false,
                visible: true,
                readonly: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "total_income",
                label: "Total Income",
                type: FieldTypes.NUMBER,
                description: "The Total Income from the business",
                value: 2000,
                order: 30,
                required: false,
                visible: false,
                readonly: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "current_stage",
                label: "Current Stage",
                type: FieldTypes.SELECT,
                description: "Stage to describe the business",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                options: localPlanStage,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "stimated_cost",
                label: "Estimated Cost",
                type: FieldTypes.NUMBER,
                description: "Estimated Cost the business require to start",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "business_groups",
                label: "Business Groups",
                type: FieldTypes.NUMBER,
                description: "Involved business groups",
                value: "",
                order: 30,
                required: false,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "opportunity_created",
                label: "Job Opportunity",
                type: FieldTypes.NUMBER,
                description: "Number Job oportunities created",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "monthly_revenue",
                label: "Monthly Revenue",
                type: FieldTypes.NUMBER,
                description: "Monthly revenue from total income",
                value: "",
                order: 20,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "score",
                label: "Score",
                type: FieldTypes.NUMBER,
                description: "Calculated Score",
                value: "",
                order: 20,
                required: false,
                visible: true,
                readonly: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "description",
                label: "Description",
                type: FieldTypes.TEXTAREA,
                description: "Plan Explanation",
                value: "",
                order: 20,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            }
        ],

        onsubmit: async (token: string, fields: IField[]): Promise<any> => {
            let new_plan = await mapValue(fields, token, "businessplan");
            new_plan.total_income = 1;
            return await MainAPI.createNew(token, "businessplan", new_plan);
        },

        onload: async (token: string, all_fields: IField[], localData: (LocalData | any), loggedUser: (AuthResult | any), id?: any): Promise<IField[]> => {

            let is_admin = loggedUser.Roles.includes(UserRoles.ADMIN);
            let is_personal = loggedUser.Roles.includes(UserRoles.PERSONAL);
            let is_company = loggedUser.Roles.includes(UserRoles.COMPANY);
            let is_bank = loggedUser.Roles.includes(UserRoles.BANK);

            let data = {};
            if((parseInt(id) > 0)){
                data = await MainAPI.getSingle(token, "businessplan", id);
            }

            return mapSingle(all_fields, {
                id: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.id : "",
                }),
                sector: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.sector : "",
                    readonly: is_bank
                }),
                sub_sector: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.sub_sector : "",
                }),
                user_id: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.user_id : "",
                    readonly: is_bank,
                    options: [...localData.PersonalUsers, ...localData.CompanyUsers]
                }),
                company_id: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.company_id : "",
                    readonly: true,
                    options: localData.Companies
                }),
                total_income: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.total_income : "",
                    readonly: is_bank
                }),
                current_stage: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.current_stage : "",
                    readonly: is_bank
                }),
                stimated_cost: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.stimated_cost : "",
                    readonly: is_bank
                }),
                business_groups: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.business_groups : "",
                    visible: !is_personal,
                    readonly: !is_company && !is_admin
                }),
                monthly_revenue: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.monthly_revenue : "",
                    readonly: is_bank
                }),
                opportunity_created: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.opportunity_created : "",
                    readonly: is_bank
                }),
                description: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.description : "",
                    readonly: is_bank
                }),
                score: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.score : "",
                    visible: !is_personal && !is_company
                })
            }, data);

        },

        listLoader: async (token: string, pageNumber: number, pageSize: number, localData: (LocalData | any), condition?: any): Promise<IPagination<any>> => {

            let data = await MainAPI.getAll(token, "businessplan", pageNumber, pageSize, condition);
            // let temp_items = [];
            // let temp_items = data.Items.map(dt => ({...dt, date: Utils.convertISOToDate(dt.date)}));
            // data.Items = temp_items;
            return data;

        }
    },
    {
        title: "Loan",
        id: "tbl_loan",
        roles: [UserRoles.ADMIN, UserRoles.BANK, UserRoles.PERSONAL, UserRoles.COMPANY],
        fields: [
            {
                id: "id",
                label: "Identifier",
                type: FieldTypes.NUMBER,
                description: "identifier",
                value: "",
                order: 1,
                required: false,
                visible: true,
                readonly: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "bank",
                label: "Bank",
                type: FieldTypes.REFERENCE,
                description: "Loan provider bank",
                value: "",
                references: "tbl_bank",
                order: 10,
                required: true,
                visible: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "user_id",
                label: "Personal",
                type: FieldTypes.REFERENCE,
                description: "Loan Requester Personal User",
                value: "",
                references: "tbl_user",
                order: 10,
                required: true,
                visible: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "company_id",
                label: "Company",
                type: FieldTypes.REFERENCE,
                description: "Loan Requester Company",
                value: "",
                references: "tbl_company",
                order: 10,
                required: false,
                visible: true,
                readonly: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "loan_amount",
                label: "Loan Amount",
                type: FieldTypes.NUMBER,
                description: "Amount of money credit",
                value: "",
                order: 20,
                required: true,
                visible: true,
                readonly: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "repayment_period",
                label: "Repayment Period",
                type: FieldTypes.NUMBER,
                description: "Number of months needed to repay the loan",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "business_plan_id",
                label: "Business Plan",
                type: FieldTypes.REFERENCE,
                description: "Selected business plan for this loan",
                value: "",
                references: "tbl_businessplan",
                order: 50,
                required: true,
                visible: true,
                readonly: false,
                options: [],
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "collateral_type",
                label: "Collater Type",
                type: FieldTypes.SELECT,
                description: "Type of Collateral",
                value: CollateralType.Building,
                order: 40,
                required: false,
                visible: false,
                readonly: true,
                options: localCollateralTypes,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "job_status",
                label: "Job Status",
                type: FieldTypes.SELECT,
                description: "Describe how the user job is going",
                value: "",
                order: 50,
                required: false,
                visible: true,
                readonly: false,
                options: localJobStatus,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "status",
                label: "Status",
                type: FieldTypes.SELECT,
                description: "Loan status",
                value: "",
                order: 50,
                required: false,
                visible: true,
                readonly: true,
                options: localLoanStatus,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "score",
                label: "Score",
                type: FieldTypes.NUMBER,
                description: "Calculated Score",
                value: "",
                order: 50,
                required: false,
                visible: true,
                readonly: true,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "loan_reason",
                label: "Reason",
                type: FieldTypes.TEXTAREA,
                description: "Loan Reason",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
        ],
        actions: [
            {
                roles: [UserRoles.ADMIN, UserRoles.PERSONAL, UserRoles.COMPANY],
                lable: "Update",
                class: "btn-light bg-white shadow-sm",
                action: async (token: string, fields: IField[]) => {
                    await MainAPI.update(token, "loan", await mapValue(fields, token, "loan"));
                }
            },
            {
                roles: [UserRoles.ADMIN, UserRoles.BANK],
                lable: "Approve",
                class: "btn-outline-success shadow-sm",
                condition: (token: string, fields: IField[]): boolean => {
					let state_field = fields.find(fld => fld.id == "status");
					return (state_field != undefined && LoanStatus.Requested == state_field.value);
				},
                action: async (token: string, fields: IField[]) => {
                    let new_instance = await mapValue(fields, token, "loan");
                    new_instance.status = LoanStatus.Approved;
                    await MainAPI.update(token, "loan", new_instance);
                }
            },
            {
                roles: [UserRoles.PERSONAL, UserRoles.COMPANY],
                lable: "Received",
                class: "btn-outline-primary shadow-sm",
                condition: (token: string, fields: IField[]): boolean => {
					let state_field = fields.find(fld => fld.id == "status");
					return (state_field != undefined && LoanStatus.Approved == state_field.value);
				},
                action: async (token: string, fields: IField[]) => {
                    let new_instance = await mapValue(fields, token, "loan");
                    new_instance.status = LoanStatus.Received;
                    await MainAPI.update(token, "loan", new_instance);
                }
            },
            {
                roles: [UserRoles.ADMIN, UserRoles.BANK],
                lable: "Repaid",
                class: "btn-warning shadow-sm",
                condition: (token: string, fields: IField[]): boolean => {
					let state_field = fields.find(fld => fld.id == "status");
					return (state_field != undefined && LoanStatus.Received == state_field.value);
				},
                action: async (token: string, fields: IField[]) => {
                    let new_instance = await mapValue(fields, token, "loan");
                    new_instance.status = LoanStatus.Repaid;
                    await MainAPI.update(token, "loan", new_instance);
                }
            },
            {
                roles: [UserRoles.ADMIN, UserRoles.BANK],
                lable: "Reject",
                class: "btn-primary shadow-sm",
                condition: (token: string, fields: IField[]): boolean => {
					let state_field = fields.find(fld => fld.id == "status");
					return (state_field != undefined && LoanStatus.Requested == state_field.value);
				},
                action: async (token: string, fields: IField[]) => {
                    let new_instance = await mapValue(fields, token, "loan");
                    new_instance.status = LoanStatus.Rejected;
                    await MainAPI.update(token, "loan", new_instance);
                }
            }
        ],

        relatedList: [
        ],

        onsubmit: async (token: string, fields: IField[], loggedUser: AuthResult): Promise<void> => {

            let new_loan = await mapValue(fields, token, "loan");
            new_loan.user_id = loggedUser.Id;
            return await MainAPI.createNew(token, "loan", new_loan);

        },

        onload: async (token: string, all_fields: IField[], localData: (LocalData | any), loggedUser: (AuthResult | any), id?: any): Promise<IField[]> => {

            let is_admin = loggedUser.Roles.includes(UserRoles.ADMIN);
            let is_personal = loggedUser.Roles.includes(UserRoles.PERSONAL);
            let is_company = loggedUser.Roles.includes(UserRoles.COMPANY);
            let is_bank = loggedUser.Roles.includes(UserRoles.BANK);

            let data = {};
            if((parseInt(id) > 0)){
                data = await MainAPI.getSingle(token, "loan", id);
            }

            return mapSingle(all_fields, {
                id: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.id : "",
                }),
                user_id: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.user_id : "",
                    options: [...localData.CompanyUsers, ...localData.PersonalUsers],
                    readonly: is_bank,
                    visible: true
                }),
                company_id: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.company_id : "",
                    options: localData.Companies,
                    visible: !is_personal
                }),
                bank: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.bank : "",
                    readonly: is_bank,
                    options: localData.Banks
                }),
                loan_amount: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.loan_amount : "",
                    readonly: is_bank
                }),
                loan_reason: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.loan_reason : "",
                    readonly: is_bank
                }),
                repayment_period: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.repayment_period : "",
                    readonly: is_bank
                }),
                collateral_type: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.collateral_type : "",
                    readonly: is_bank
                }),
                business_plan_id: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.business_plan_id : "",
                    options: localData.BusinessPlan,
                    readonly: is_bank
                }),
                score: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.score : "",
                    visible: !is_personal && !is_company
                }),
                job_status: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.job_status : "",
                    Visible: !is_company,
                    readonly: is_bank,
                    required: is_personal
                }),
                status: (dt: any) => ({
                    value: (parseInt(id) > 0) ? dt.status : "",
                    Visible: true,
                    readonly: !is_admin,
                    required: false
                })
            }, data);

        },

        listLoader: async (token: string, pageNumber: number, pageSize: number, localData: (LocalData | any), condition?: any): Promise<IPagination<any>> => {

            let data = await MainAPI.getAll(token, "loan", pageNumber, pageSize, condition);

            let records = data.Items.map((rec) => ({
                ...rec,
                bank: Utils.getFromArray("value", rec.bank, "label", localData.Banks),
                user_id: Utils.getFromArray("value", rec.user_id, "label", localData.Users),
                job_status: Utils.getFromArray("value", rec.job_status, "label", localJobStatus),
                status: Utils.getFromArray("value", rec.status, "label", localLoanStatus),
            }));

            data.Items = records;
            return data;

        }
    },
    {
        title: "Personal Economic",
        id: "tbl_economic",
        roles: [UserRoles.ADMIN, UserRoles.PERSONAL, UserRoles.BANK],
        fields: [
            {
                id: "id",
                label: "Identifier",
                type: FieldTypes.NUMBER,
                description: "identifier",
                value: "",
                order: 1,
                required: false,
                visible: true,
                readonly: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "user_id",
                label: "Account",
                type: FieldTypes.REFERENCE,
                description: "Related User Account",
                value: "",
                references: "tbl_user",
                order: 10,
                required: true,
                visible: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "field_of_employment",
                label: "Employment Field",
                type: FieldTypes.TEXT,
                description: "Field of Employment",
                value: "",
                order: 20,
                required: true,
                visible: false,
                readonly: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "experience",
                label: "Experience",
                type: FieldTypes.NUMBER,
                description: "Years of Experience",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "source_of_income",
                label: "Income Source",
                type: FieldTypes.NUMBER,
                description: "Number of Income sources",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "dti",
                label: "DTI",
                type: FieldTypes.NUMBER,
                description: "Calculated Dept to Income ratio",
                value: 0,
                order: 50,
                required: false,
                visible: false,
                readonly: true,
                options: localUserState,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "current_loans",
                label: "Current Loans",
                type: FieldTypes.NUMBER,
                description: "Current Number of Received and unrepaid loans",
                value: "",
                order: 40,
                required: false,
                visible: true,
                readonly: true,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "repaid_loans",
                label: "Repaid Loans",
                type: FieldTypes.NUMBER,
                description: "Number of Previous Repaid loans",
                value: "",
                order: 50,
                required: false,
                visible: true,
                readonly: true,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "score",
                label: "Score",
                type: FieldTypes.NUMBER,
                description: "Calculated Score",
                value: "",
                order: 50,
                required: false,
                visible: true,
                readonly: true,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "assets",
                label: "Assets",
                type: FieldTypes.TEXTAREA,
                description: "Assets Description",
                value: "",
                order: 50,
                required: true,
                visible: true,
                readonly: false,
                options: localUserState,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            }
        ],
        actions: [
            {
                roles: [UserRoles.ADMIN, UserRoles.PERSONAL],
                lable: "Update",
                class: "btn-light bg-white shadow-sm",
                action: async (token: string, fields: IField[]) => {
                    let new_company: any = await mapValue(fields, token, "economic")
                    await MainAPI.update(token, "economic", new_company);
                }
            }
        ],

        relatedList: [],

        onsubmit: async (token: string, fields: IField[]): Promise<void> => {

            let new_cashier: any = await mapValue(fields, token, "economic");
            new_cashier.dti = 0;
            return await MainAPI.createNew(token, "economic", new_cashier);

        },

        onload: async (token: string, all_fields: IField[], localData: (LocalData | any), loggedUser: (AuthResult | any), id?: any): Promise<IField[]> => {

            let is_admin = loggedUser.Roles.includes(UserRoles.ADMIN);
            let is_personal = loggedUser.Roles.includes(UserRoles.PERSONAL);
            let is_company = loggedUser.Roles.includes(UserRoles.COMPANY);

            let data = {};

            if (parseInt(id) > 0) {
                data = await MainAPI.getSingle(token, "economic", id);
            }

            return await mapSingle(all_fields, {
                "id": (data: any) => ({
                    value: parseInt(id) > 0 ? data.id : ""
                }),
                "user_id": (data: any) => ({
                    value: parseInt(id) > 0 ? parseInt(data.user_id) : "",
                    options: localData.PersonalUsers,
                    readonly: !is_admin && !is_personal,
                    visible: !is_company
                }),
                "field_of_employment": (data: any) => ({
                    value: parseInt(id) > 0 ? data.field_of_employment : "",
                    readonly: !is_admin && !is_personal,
                    visible: !is_company
                }),
                "experience": (data: any) => ({
                    value: parseInt(id) > 0 ? data.experience : "",
                    readonly: !is_admin && !is_personal,
                    visible: !is_company
                }),
                "source_of_income": (data: any) => ({
                    value: parseInt(id) > 0 ? data.source_of_income : "",
                    readonly: !is_admin && !is_personal,
                    visible: !is_company
                }),
                "current_loans": (data: any) => ({
                    value: parseInt(id) > 0 ? data.current_loans : "",
                    visible: !is_company
                }),
                "repaid_loans": (data: any) => ({
                    value: parseInt(id) > 0 ? data.repaid_loans : "",
                    visible: !is_company
                }),
                "dti": (data: any) => ({
                    value: 0,
                    // value: parseInt(id) > 0 ? data.dti : "",
                    // readonly: !is_admin && !is_personal,
                    // visible: !is_company
                }),
                "score": (data: any) => ({
                    value: parseInt(id) > 0 ? data.score : "",
                    visible: !is_personal && !is_company
                }),
                "assets": (data: any) => ({
                    value: parseInt(id) > 0 ? data.assets : "",
                    readonly: !is_admin && !is_personal,
                    visible: !is_company
                })
            }, data);

        },

        listLoader: async (token: string, pageNumber: number, pageSize: number, localData: (LocalData | any), condition?: any): Promise<IPagination<any>> => {

            let data = await MainAPI.getAll(token, "economic", pageNumber, pageSize, condition);

            let records = data.Items.map((rec) => ({
                ...rec,
                user_id: Utils.getFromArray("value", rec.user_id, "label", localData.PersonalUsers),
            }));

            data.Items = records;
            return data;

        }
    },
    {
        title: "Company Economic",
        id: "tbl_companyeconomic",
        roles: [UserRoles.ADMIN, UserRoles.COMPANY, UserRoles.BANK],
        fields: [
            {
                id: "id",
                label: "Identifier",
                type: FieldTypes.NUMBER,
                description: "identifier",
                value: "",
                order: 1,
                required: false,
                visible: true,
                readonly: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "user_id",
                label: "Company",
                type: FieldTypes.REFERENCE,
                description: "Related Company",
                value: "",
                references: "tbl_company",
                order: 10,
                required: true,
                visible: true,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "name",
                label: "Name",
                type: FieldTypes.TEXT,
                description: "Company Name",
                value: "",
                order: 20,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "employee_count",
                label: "Employee Count",
                type: FieldTypes.NUMBER,
                description: "Number of Employees the company has",
                value: "",
                order: 20,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "company_age",
                label: "Company Age",
                type: FieldTypes.NUMBER,
                description: "Years of Experience",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "idr",
                label: "IDR",
                type: FieldTypes.NUMBER,
                description: "Calculated IDR",
                value: "",
                order: 30,
                required: true,
                visible: true,
                readonly: false,
                // options?: { value: string, label: string }[];
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "fccr",
                label: "FCCR",
                type: FieldTypes.NUMBER,
                description: "Calculated FCCR",
                value: "",
                order: 50,
                required: true,
                visible: true,
                readonly: false,
                options: localUserState,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "current_loans",
                label: "Current Loans",
                type: FieldTypes.NUMBER,
                description: "Current Number of Approved and unrepaid loans",
                value: "",
                order: 40,
                required: false,
                visible: true,
                readonly: true,
                options: localRoles,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "repaid_loans",
                label: "Repaid Loans",
                type: FieldTypes.NUMBER,
                description: "Current Number of Repaid loans",
                value: "",
                order: 50,
                required: false,
                visible: true,
                readonly: true,
                options: localUserState,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "score",
                label: "Score",
                type: FieldTypes.NUMBER,
                description: "Calculated Score",
                value: "",
                order: 50,
                required: false,
                visible: true,
                readonly: true,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            },
            {
                id: "assets",
                label: "Assets",
                type: FieldTypes.TEXTAREA,
                description: "Assets Description",
                value: "",
                order: 50,
                required: true,
                visible: true,
                readonly: false,
                options: localUserState,
                onchange: async (token: string, fields: IField[], value: any, set_field: (vl: IField) => void): Promise<any> => {
                    return value;
                }
            }
        ],
        actions: [
            {
                roles: [UserRoles.ADMIN, UserRoles.COMPANY],
                lable: "Update",
                class: "btn-light bg-white shadow-sm",
                action: async (token: string, fields: IField[]) => {
                    await MainAPI.update(token, "companyeconomic", await mapValue(fields, token, "companyeconomic"));
                }
            }
        ],

        relatedList: [
            // {
            //     id: "rl_ticket",
            //     form: "tbl_ticket",
            //     lable: "Tickets",
            //     relatingColumn: "CompanyId",
            //     loader: (fields: IField[]): any => {

            //         let field = fields.find(fld => (fld.id == "Id"));
            //         return {
            //             cashierId: field ? field.value : ""
            //         };

            //     }
            // }
        ],

        onsubmit: async (token: string, fields: IField[]): Promise<void> => {
            return await MainAPI.createNew(token, "companyeconomic", await mapValue(fields, token, "companyeconomic"));
        },

        onload: async (token: string, all_fields: IField[], localData: (LocalData | any), loggedUser: (AuthResult | any), id?: any): Promise<IField[]> => {

            let is_admin = loggedUser.Roles.includes(UserRoles.ADMIN);
            let is_personal = loggedUser.Roles.includes(UserRoles.PERSONAL);
            let is_company = loggedUser.Roles.includes(UserRoles.COMPANY);

            let data = {};

            if (parseInt(id) > 0) {
                data = await MainAPI.getSingle(token, "companyeconomic", id);
            }

            return await mapSingle(all_fields, {
                "id": (data: any) => ({
                    value: parseInt(id) > 0 ? data.id : ""
                }),
                "user_id": (data: any) => ({
                    value: parseInt(id) > 0 ? parseInt(data.user_id) : "",
                    options: localData.Companies,
                    readonly: !is_admin && !is_company,
                    visible: !is_personal
                }),
                "name": (data: any) => ({
                    value: parseInt(id) > 0 ? data.name : "",
                    readonly: !is_admin && !is_company,
                    visible: !is_personal
                }),
                "employee_count": (data: any) => ({
                    value: parseInt(id) > 0 ? data.employee_count : "",
                    readonly: !is_admin && !is_company,
                    visible: !is_personal
                }),
                "company_age": (data: any) => ({
                    value: parseInt(id) > 0 ? data.company_age : "",
                    readonly: !is_admin && !is_company,
                    visible: !is_personal
                }),
                "current_loans": (data: any) => ({
                    value: parseInt(id) > 0 ? data.current_loans : "",
                    visible: !is_personal
                }),
                "repaid_loans": (data: any) => ({
                    value: parseInt(id) > 0 ? data.repaid_loans : "",
                    visible: !is_personal
                }),
                "idr": (data: any) => ({
                    value: parseInt(id) > 0 ? data.idr : "",
                    readonly: !is_admin && !is_company,
                    visible: !is_personal
                }),
                "fccr": (data: any) => ({
                    value: parseInt(id) > 0 ? data.fccr : "",
                    readonly: !is_admin && !is_company,
                    visible: !is_personal
                }),
                "score": (data: any) => ({
                    value: parseInt(id) > 0 ? data.score : "",
                    visible: !is_personal && !is_company
                }),
                "assets": (data: any) => ({
                    value: parseInt(id) > 0 ? data.assets : "",
                    readonly: !is_admin && !is_company,
                    visible: !is_personal
                })
            }, data);

        },

        listLoader: async (token: string, pageNumber: number, pageSize: number, localData: (LocalData | any), condition?: any): Promise<IPagination<any>> => {

            let data = await MainAPI.getAll(token, "companyeconomic", pageNumber, pageSize, condition);

            let records = data.Items.map((rec) => ({
                ...rec,
                user_id: Utils.getFromArray("value", rec.user_id, "label", localData.Companies)
            }));

            data.Items = records;
            return data;

        }
    },
];

export default tables;