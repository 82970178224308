import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login, information } from "./APIs/AuthAPI";
import AuthContext from "./Contexts/AuthContext";
import AlertContext from "./Contexts/AlertContext";
import Alert from "./Components/Extra/Alert";
import Waiting from "./Components/Extra/Waiting";
import { useCookies } from "react-cookie";
import Error from "./Views/Error";
import LoginPage from "./Views/Login";
import SideBar from "./Components/NavBars/SideBar";
import Account from "./Views/Account";
import Dashboard from "./Views/Dashboard";
import CreateCompany from "./Views/CreateCompany";
import AuthResult from "./Intefaces/AuthResult";
import LocalData from "./Intefaces/LocalData";
import ResetAccount from "./Views/ResetAccount";
import TableComponent from "./Views/TableComponent";
import ChatPage from "./Views/ChatPage";
import MainAPI from "./APIs/MainAPI";
import RequetLoan from "./Views/RequestLoan";
import Operators from "./Enums/Operators";
import FieldTypes from "./Enums/FiedTypes";
import UserRoles from "./Enums/UserRoles";
function App(params: any) {

    const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
    const [loggedUser, setLoggedUser] = useState<null | AuthResult>(null);
    const [cookies, setCookie, removeCookie] = useCookies(["login_token"]);
    const [authWaiting, setAuthWaiting] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [showWaiting, setWaiting] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<"success" | "error" | "warning" | "info">("info");
    const [alertMessage, setMessage] = useState<string>("");
    const [menu, setMenu] = useState<boolean>(false);
    const [localData, setLocalData] = useState<LocalData>({
        Users: [],
        PersonalUsers: [],
        CompanyUsers: [],
        BankUsers: [],
        Companies: [],
        Banks: [],
        BusinessPlan: []
    });

    useEffect(() => {

        const checkAuth = async (token: string) => {

            setTimeout(() => { setAuthWaiting(true); setWaiting(true); }, 1);
            let response = await information(token);
            await signIn(response.data);
            setAuthWaiting(false);
            setWaiting(false);

        };

        if (cookies.login_token && cookies.login_token != "") {
            checkAuth(cookies.login_token);
        }

    }, []);

    const setAlert = (
        message: string,
        type: "success" | "error" | "warning" | "info"
    ) => {

        setAlertType(type);
        setShowAlert(true);
        setMessage(message);

        setTimeout(() => {
            setShowAlert(false);
        }, 3000);

    }

    const signIn = async (user: AuthResult) => {
        if(user){
            setCookie("login_token", user.Token, { path: "/" });
            setLoggedUser(user);
            setLoggedIn(true);
            await loadLocalData(user);
        }
    }

    const loadLocalData = async (logged_user: AuthResult) => {

        let temp_data = localData;
        temp_data.Users = (await MainAPI.getAll(cookies.login_token, "user", 1,1000)).Items.map((cmp: any) => ({ value: cmp.id, label: cmp.FullName }));

        temp_data.BusinessPlan = (await MainAPI.getAll(cookies.login_token, "businessplan", 1,100, ((logged_user.Roles.includes(UserRoles.COMPANY) || logged_user.Roles.includes(UserRoles.PERSONAL)) ? {user_id: {
            operator:Operators.IS,
            type:FieldTypes.NUMBER,
            value:logged_user.Id
        }} : {}))).Items.map((cmp: any) => ({ value: cmp.id, label: `${cmp.sector} - ${cmp.score}` }));

        if(!logged_user.Roles.includes(UserRoles.COMPANY)) {
            temp_data.PersonalUsers = (await MainAPI.getAll(cookies.login_token, "user", 1,100, ((logged_user.Roles.includes(UserRoles.PERSONAL)) ? {id:{
                operator:Operators.IS,
                type:FieldTypes.NUMBER,
                value:logged_user.Id
            }} : {Role:{
                operator:Operators.IS,
                type:FieldTypes.TEXT,
                value:UserRoles.PERSONAL
            }}))).Items.map((cmp: any) => ({ value: cmp.id, label: cmp.FullName }));
        }

        if(!logged_user.Roles.includes(UserRoles.PERSONAL)) {
            temp_data.Companies = (await MainAPI.getAll(cookies.login_token, "company", 1,100, ((logged_user.Roles.includes(UserRoles.COMPANY)) ? {user_id:{
                operator:Operators.IS,
                type:FieldTypes.NUMBER,
                value:logged_user.Id
            }} : {}))).Items.map((cmp: any) => ({ value: cmp.id, label: cmp.name }));
    
            temp_data.CompanyUsers = (await MainAPI.getAll(cookies.login_token, "user", 1,100, ((logged_user.Roles.includes(UserRoles.COMPANY)) ? {id:{
                operator:Operators.IS,
                type:FieldTypes.NUMBER,
                value:logged_user.Id
            }} : {Role:{
                operator:Operators.IS,
                type:FieldTypes.TEXT,
                value:UserRoles.COMPANY
            }}))).Items.map((cmp: any) => ({ value: cmp.id, label: cmp.FullName }));
        }

        temp_data.Banks = (await MainAPI.getAll(cookies.login_token, "bank", 1,100, ((logged_user.Roles.includes(UserRoles.BANK)) ? {user_id:{
            operator:Operators.IS,
            type:FieldTypes.NUMBER,
            value:logged_user.Id
        }} : {}))).Items.map((cmp: any) => ({ value: cmp.id, label: cmp.name }));

        temp_data.BankUsers = (await MainAPI.getAll(cookies.login_token, "user", 1,100, ((logged_user.Roles.includes(UserRoles.PERSONAL)) ? {id:{
            operator:Operators.IS,
            type:FieldTypes.NUMBER,
            value:logged_user.Id
        }} : {Role:{
            operator:Operators.IS,
            type:FieldTypes.TEXT,
            value:UserRoles.BANK
        }}))).Items.map((cmp: any) => ({ value: cmp.id, label: cmp.FullName }));

        setLocalData(temp_data);
    }

    return (
        <AlertContext.Provider value={{ showAlert, alertType, setAlertType, setAlert, setWaiting, menu, setMenu }}>
            <AuthContext.Provider value={{
                isLoggedIn, loggedUser, setLoggedUser, setLoggedIn, setCookie, cookies, removeCookie, authWaiting, localData, signIn
            }}>
                <BrowserRouter>
                    {
                        !authWaiting && (
                            !isLoggedIn ? (
                                <Routes>
                                    <Route path="/" element={<LoginPage />} />
                                    <Route path="/reset" element={<ResetAccount />} />
                                </Routes>
                            ) : (
                                <Routes>
                                    <Route path="/chat" element={<ChatPage />} />
                                    <Route path="/list/:name" element={<TableComponent />} />
                                    <Route path="/form/:name/:r_id" element={<CreateCompany />} />
                                    <Route path="/profile" element={<Account />} />
                                     <Route path="/loanrequest" element={<RequetLoan />} />
                                    <Route path="/" element={<Dashboard />} />
                                    <Route path="*" element={<Error />} />
                                </Routes>
                            )
                        )
                    }
                {showAlert ? (<Alert message={alertMessage} color={alertType} />) : ""}
                {showWaiting ? (<Waiting />) : ""}
                {menu ? (<SideBar />) : ""}
                </BrowserRouter>
            </AuthContext.Provider>
        </AlertContext.Provider>
    );

}

export default App;