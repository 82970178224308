import React, { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../Contexts/AuthContext";
import Utils from "../Models/Utils";
import UserRoles from "../Enums/UserRoles";
import { IconButton } from "@mui/material";
import { Menu, PlayArrow } from "@mui/icons-material";
import Chart from 'chart.js/auto';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AlertContext from "../Contexts/AlertContext";
import SlidingBanksData from "../Views/SlidingBanksData"
import TopNav from "../Components/NavBars/TopNav";
import MainAPI from "../APIs/MainAPI"
import { Link } from "react-router-dom";
function Dashboard() {

	const { setAlert, setWaiting, setMenu, menu } = useContext(AlertContext);
	const { loggedUser, cookies, localData } = useContext(AuthContext);
	const [balance, setBalance] = useState<Map<number, { tickets: any[], pay_in: number, pay_out: number }>>(new Map());
	const [inputs, setInputs] = useState({
		start: "",
		end: "",
		company: ""
	});

	const [isAdmin, setIsAdmin] = useState<boolean>(true);
	const [cardData, setCardData] = useState<any>({
		today: 0,
		thisMonth: 0,
		thisYear: 0,
		thisQuarter: 0
	});
	const [numberOfApproved, SetNumberOfApproved] = useState(0.1)
	const [numberofPending, setnumberOfPending] = useState(371)
	const [numberofRejected, setnumberofRejected] = useState(500)
	const [companyId, setCompanyId] = useState<any[]>([])
	const [bankId, setbankId] = useState<any[]>([])

	const chart_ref = useRef(null);
	const [loanTitle, setLoanTitle] = useState("")
	// const [banks, setBanks] = useState([
	// 	{
	// 		"name": "Bunna Bank",
	// 		"imageUrl": "images/bunna.webp",
	// 		"headOffice": "Addis Ababa, Ethiopia",
	// 		"phoneNumber": "+251 XXXXXXXXXX",
	// 		"emailAddress": "info@bunnabank.et"
	// 	},
	// 	{
	// 		"name": "Commercial Bank of Ethiopia",
	// 		"imageUrl": "images/cbe.jpg",
	// 		"headOffice": "Addis Ababa, Ethiopia",
	// 		"phoneNumber": "+251951",
	// 		"emailAddress": "info@cbe.com.et"
	// 	},
	// 	{
	// 		"name": "Awash Bank",
	// 		"imageUrl": "images/awash.jpg",
	// 		"headOffice": "Addis Ababa, Ethiopia",
	// 		"phoneNumber": "+25111 618 9522",
	// 		"emailAddress": "customercare@awashbank.com"
	// 	},
	// 	{
	// 		"name": "Dashen Bank",
	// 		"imageUrl": "images/dashen.jpg",
	// 		"headOffice": "Addis Ababa, Ethiopia",
	// 		"phoneNumber": "+25111 123 4567",
	// 		"emailAddress": "info@dashenbank.com"
	// 	},
	// 	{
	// 		"name": "Lucy Bank",
	// 		"imageUrl": "images/lucy.jpg",
	// 		"headOffice": "Addis Ababa, Ethiopia",
	// 		"phoneNumber": "+251 XXXXXXXXXX",
	// 		"emailAddress": "info@lucybank.et"
	// 	}
	// ]
	// );
	const [banks, setBanks] = useState([])



	const [ListOFusers, setListOfUsers] = useState<any[]>([]);

	const [allLoans, setAllLoans] = useState<any[]>([]);
	const [filterdLoansList, setFilteredLoanList] = useState<any[]>([]);
	const [selectedFilters, setSelectedFilters] = useState("")


	function handleFilter(inp: string) {
		if (selectedFilters == inp) {
			setFilteredLoanList(allLoans)
			setSelectedFilters("")

		}
		else {
			setFilteredLoanList(allLoans.filter(obj => obj.status === inp))
			setSelectedFilters(inp)
		}
	}

	const main_loader = async () => {
		var numApproved=[]
		var numPending=[]
		var numRejected=[]
		setIsAdmin(loggedUser.Roles.includes(UserRoles.ADMIN));
		// cardDataLoader();
		// loadBalance();

		setTimeout(() => {setWaiting(true)}, 1);
		async function getcompanyid() {
            try {

                var condition = {
                    "user_id": {
                        "operator": "equal",
                        "value": loggedUser.Id,
                        "type": "number"
                    }
                }
                var token = cookies.login_token
                let response = await MainAPI.getAll(token, "company", 1, 100, condition);
                var companyId = response.Items
				if(response.Items.length>0)
               	{ setCompanyId(companyId[0].id)}

            } catch (error: any) {
                setAlert(error.message, "error");
            }
        }
        await getcompanyid()
		async function getBankid() {
            try {

                var condition = {
                    "user_id": {
                        "operator": "equal",
                        "value": loggedUser.Id,
                        "type": "number"
                    }
                }
                var token = cookies.login_token
                let response = await MainAPI.getAll(token, "bank", 1, 100, condition);
                var bankId = response.Items
				// if(bankId[0].id)
                // 	setbankId(bankId[0].id)

            } catch (error: any) {
                setAlert(error.message, "error");
            }
        }
        await getBankid()

		var condition = {}
		if (loggedUser.Roles.includes(UserRoles.ADMIN)) {
			setLoanTitle("All Loans")

		}
		else if (loggedUser.Roles.includes(UserRoles.PERSONAL)) {
			setLoanTitle("My Loans")
			condition = {
				"user_id": {
					"operator": "equal",
					"value": loggedUser.Id,
					"type": "number"
				}
			}

		}
		else if (loggedUser.Roles.includes(UserRoles.COMPANY)) {

			setLoanTitle("My Company Loans")
			condition = {
				"company_id": {
					"operator": "equal",
					"value": companyId,
					"type": "number"
				}
			}

		}
		else if (loggedUser.Roles.includes(UserRoles.BANK)) {
			setLoanTitle("Loans from my Bank")
			condition = {
				"bank": {
					"operator": "equal",
					"value": bankId,
					"type": "number"
				}
			}

		}
		async function getLoans() {
			try {

				var token = cookies.login_token
				let response = await MainAPI.getAllRelated(token, "loan", 1, 100, condition);
				var loans = response.Items
				setAllLoans(loans)
				setFilteredLoanList(loans)

				numApproved= loans.filter(
					(application) => application.status === "approved"
				  );
				numPending= loans.filter(
					(application) => application.status === "requested"
				  );
				numRejected= loans.filter(
					(application) => application.status === "rejected"
				  );

				 await setnumberOfPending(numPending.length)
				 await setnumberofRejected(numRejected.length)
				 await SetNumberOfApproved(numApproved.length)

				console.log(loans)

			} catch (error: any) {
				setAlert(error.message, "error");
			}
		}
		await getLoans()

		async function getListofUsers() {
			try {

				var token = cookies.login_token
				let response = await MainAPI.getAll(token, "user", 1, 100);
				var users = response.Items
				setListOfUsers(users)

				setWaiting(false);

			} catch (error: any) {
				setAlert(error.message, "error");
			}
		}
		await getListofUsers()

		const data = {
			labels: [
				'Approved',
				'Pending',
				'Declined'
			],
			datasets: [{
				label: 'Summery',
				data: [numberOfApproved, numberofPending, numberofRejected],
				backgroundColor: [
					'rgb(255, 99, 132)',
					'rgb(54, 162, 235)',
					'rgb(255, 205, 86)'
				],
				hoverOffset: 4
			}]
		};
		if(numberOfApproved!=0.1)
		{chartLoader(data);}

		setTimeout(() => {setWaiting(false)}, 1);

	}

	useEffect(() => {
		main_loader();
	}, []);

useEffect(()=>{
	const data = {
		labels: [
			'Approved',
			'Pending',
			'Declined'
		],
		datasets: [{
			label: 'Summery',
			data: [numberOfApproved, numberofPending, numberofRejected],
			backgroundColor: [
				'rgb(255, 99, 132)',
				'rgb(54, 162, 235)',
				'rgb(255, 205, 86)'
			],
			hoverOffset: 4
		}]
	};
	if(numberOfApproved!=0.1)
		{chartLoader(data);}
},[numberOfApproved])

	const chartLoader = (data: any) => {

		if (chart_ref.current) {

			const chart_instance = new Chart(chart_ref.current, {
				type: 'doughnut',
				data: data,
				options: {
					// onClick: (e) => {
					// const canvasPosition = getRelativePosition(e, chart);

					// // Substitute the appropriate scale IDs
					// const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);
					// const dataY = chart.scales.y.getValueForPixel(canvasPosition.y);
					// }
				}
			});
		} else {
			console.log("element not found");
		}
	}

	let balance_table = [];
	for (let [key, value] of balance) {

		let difference = value.pay_in - value.pay_out;
		let cls = difference > 1000 ? "bg-success" : ((difference <= 0) ? "bg-danger" : "bg-secondary");
		balance_table.push(<tr>
			<td>{isAdmin ? Utils.getFromArray("value", key, "label", localData.Companies) : Utils.getFromArray("value", key, "label", localData.Cashiers)}</td>
			<td>{(difference / value.pay_in) * 100}%</td>
			<td>{value.pay_in}ETB</td>
			<td>{value.pay_out}ETB</td>
			<td><span className={`badge ${cls}`}>{difference}ETB</span></td>
		</tr>)

	}

	return (
		<div className="w-100" >
			<TopNav />
			<div className="w-100" style={{ height: "30%", overflow: "hidden", position: "relative", zIndex: "100" }}>
				<SlidingBanksData />
			</div>
			<div className="container">
				<div className="row">
					<div className="col-sm-12 col-md-4 col-lg-3">
						<div className="card shadow">
							<div className="card-body">
								<canvas ref={chart_ref} className="w-60 h-60" />
								<hr />
								<h6 className="card-title">Summary</h6>

								<div className="card bg-light mb-3 w-100" style={{ border: "none", textAlign: "center" }}>
									<button style={{ border: "none" }} onClick={() => handleFilter("approved")}>
										<div className="card-body d-flex justify-content-between">
											<span className="card-text">Approved</span>

											<span className="card-text">{numberOfApproved}</span>
										</div>
									</button>
								</div>
								<div className="card bg-light mb-3 w-100" style={{ border: "none", textAlign: "center" }}>
									<button style={{ border: "none" }} onClick={() => handleFilter("requested")}>
										<div className="card-body d-flex justify-content-between">
											<span className="card-text">Pending</span>
											<span className="card-text">{numberofPending}</span>
										</div>
									</button>
								</div>
								<div className="card bg-light mb-3 w-100" style={{ border: "none", textAlign: "center" }}>
									<button style={{ border: "none" }} onClick={() => handleFilter("rejected")}>
										<div className="card-body d-flex justify-content-between">
											<span className="card-text">Declined </span>
											<span className="card-text">{numberofRejected}</span>
										</div>
									</button>
								</div>

							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-8 col-lg-9">
						<div className="card shadow w-100" style={{}}>
							<div className="card-body py-3 " >
								<div className="d-flex justify-content-between border-bottom py-2 align-items-center">
									<h5 className="card-title">{loanTitle} {selectedFilters && <span>showing {selectedFilters}</span>}</h5>
									{(loggedUser.Roles.includes(UserRoles.PERSONAL) || loggedUser.Roles.includes(UserRoles.COMPANY) )&& <Link to={"/loanrequest"} className="btn btn-sm btn-primary">
										Request a Loan
									</Link>}
								</div>
								{/* <hr /> */}
								<table className="table table-hover">
									<thead>
										<tr>
											<th scope="col">Bank Name</th>
											<th scope="col">Loan amount</th>
											<th scope="col">Payment Period</th>
											<th scope="col">score</th>
										</tr>
									</thead>
									<tbody>
									{filterdLoansList.length == 0&&
									<tr className="m-5" style={{paddingTop:"10px"}} >

									No records to display
									

								</tr>}
										{filterdLoansList.length > 0 && filterdLoansList.map((loan, index) => (

											<tr >

												<td><Link to={"/form/tbl_loan/" + loan.id}>{loan.bank_loan_bankTobank.name}</Link></td>
												<td>{loan.loan_amount}</td>
												<td>{loan.repayment_period}</td>
												<td>{loan.score}</td>

											</tr>

										))}

									</tbody>
								</table>
							</div>
						</div>
					</div>

				</div>


				{(loggedUser.Roles.includes(UserRoles.ADMIN) || loggedUser.Roles.includes(UserRoles.BANK)) && <div>
					<div className="card shadow w-100 " style={{marginTop:"20px"}}>
						<div className="card-body py-3 " >
							<div className="card-body py-3 " >
								<div className="d-flex justify-content-between border-bottom py-2 align-items-center">
									<h5 className="card-title">All User</h5>

								</div>
								{/* <hr /> */}
								<table className="table table-hover">
									<thead>
										<tr>
											<th scope="col">Full Name</th>
											<th scope="col">Phone Number</th>
											<th scope="col">Email</th>
											<th scope="col">Role</th>
											<th scope="col">Status</th>
										</tr>
									</thead>
									<tbody>
										{ListOFusers.length > 0 && ListOFusers.map((user, index) => (
											<tr>
												<td>{user.FullName}</td>
												<td>{user.Phone}</td>
												<td>{user.Email}</td>
												<td>{user.Role}</td>
												<td>{user.Status}</td>
											</tr>
										))}

									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>}
			</div>

		</div>
	);
}

export default Dashboard;

//<img src="/images/dashboard_image.png" alt="computer user" style={{width: "350px", position: "absolute", bottom: "-16%", left: "60%"}} /><img src="/images/dashboard_image.png" alt="computer user" style={{width: "350px", position: "absolute", bottom: "-16%", left: "60%"}} />